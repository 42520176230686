<template>
  <!-- 数据资源 左侧导航栏 -->
  <div class="leftnav">
    <!-- 点击隐藏/显示左侧导航栏，只剩下图标 -->
    <!-- <div class="colspan" @click="isCollapse = !isCollapse">
      <i v-show="!isCollapse" class="el-icon-caret-left iconcollapse"></i>
      <i v-show="isCollapse" class="el-icon-caret-right iconcollapse"></i>
    </div> -->
    <el-tooltip
      class="item"
      effect="dark"
      :enterable="false"
      :content="isCollapse ? '开导航栏' : '收导航栏'"
      placement="top"
      :open-delay="50"
    >
      <div class="colspan" @click="isCollapse = !isCollapse">
        <i class="el-icon-s-fold icon" v-show="!isCollapse"></i>
        <i class="el-icon-s-unfold icon" v-show="isCollapse"></i>

        <!-- <img v-show="!isCollapse" class="" src="~@assets/imgs/left-nav-icon/arrow-l.png"/>
      <img v-show="isCollapse" class="" src="~@assets/imgs/left-nav-icon/arrow-r.png" /> -->
      </div>
    </el-tooltip>

    <!-- 顶部图标 -->
    <div class="top" :class="{ tops: isCollapse }">
      <div class="logo">
        <img
          :src="userInfos.image_sys"
          :class="{ 'logo-img-small': isCollapse }"
          alt=""
        />
      </div>
    </div>

    <!-- 导航栏 -->
    <div class="menu_box" ref="menu_box">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <el-menu
          :default-active="defaultActive"
          mode="vertical"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isCollapse"
          unique-opened
          ref="refmenu"
          text-color="#fff"
          active-text-color="#3983e7"
          :default-openeds="openeds"
        >
          <fragment v-for="item in leftNavData" :key="item.index">
            <!-- 判断是否有子类 -->
            <el-menu-item
              v-if="!item.childe"
              :index="item.index"
              @click="menuClick(item)"
              class="one_menu_item"
              :ref="'refmenuitem' + item.index"
              v-show="!item.childe && item.isShow"
            >
              <i :class="item.icon"></i>
              <span slot="title" class="submenuText1" style>{{
                item.name
              }}</span>
            </el-menu-item>
            <!-- 多级菜单 -->
            <el-submenu
              :ref="'submenu' + item.index"
              v-else
              :key="item.index"
              :index="item.index"
               v-show="item.childe && item.isShow"
            >
              <template slot="title">
                <i style="color: #ffffff" :class="item.icon"></i>
                <span slot="title" class="submenuText1">{{ item.name }}</span>
              </template>

              <el-menu-item
                v-for="itemc in item.childe"
                :key="itemc.index"
                :index="itemc.index"
                @click="menuClick(itemc)"
                class="submenu_item"
                v-show="itemc.isShow"
              >
                <span slot="title" class="submenuText2">{{ itemc.name }}</span>
              </el-menu-item>
            </el-submenu>
          </fragment>
        </el-menu>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { leftNavData } from "./conf/leftNavData.js";
import { mapState } from "vuex";
// import TreeComp from '@components/content/treecomp/TreeComp'
export default {
  components: {
    // TreeComp
  },
  data() {
    return {
      isCollapse: false,
      openeds: ["home"],
      leftNavData: [],
      defaultActive: "home",
      userInfos: {},
    };
  },

  mounted() {
    // console.log(this.$refs)
    this.$bus.$on("jumpToPage", this.jumpToPage);
    this.$bus.$on("popstate", this.popstate);
  },

  computed: {
    ...mapState({ userInfo: (state) => state.userInfo }),
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    // 权限处理
    verifyFilterLeftNavList(){
      const right = this.userInfos.right
      this.leftNavData=leftNavData
      this.leftNavData.forEach(item => {
        if(item.childe && item.childe.length){
          item.childe.forEach(item2 => {
            item2.isShow = right[item2.rightName]
          })
        }
        item.isShow = right[item.rightName]
      })
    },
    jumpToPage() {
      let parentIndex = this.$store.state.currentParnetIndex;
      let PositionIndex = this.$store.state.currentPosition;
      let submenuParent = this.$refs["submenu" + parentIndex][0];
      console.log(submenuParent, "submenuParent.active");
      this.openeds.splice(0, 1, parentIndex);
      // if (!submenuParent.active) {
      //解决js线程阻塞ui线程 菜单卡顿问题
      setTimeout(() => {
        submenuParent.$children[PositionIndex].$el.click();
      }, 350);
      // }
    },
    popstate(parentIndex){
        this.$refs["refmenuitem" + parentIndex][0].$el.click();
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      //展开菜单时 默认选择第一项子菜单
      let submenuDom = this.$refs["submenu" + key][0];
      //当菜单不处于活跃状态时选中
      if (!submenuDom.active) {
        //解决js线程阻塞ui线程 菜单卡顿问题
        setTimeout(() => {
          submenuDom.$children[0].$el.click();
        }, 350);
      }
    },
    handleClose(key, keyPath) {},

    //点击菜单
    menuClick(item) {
      let index = item.index,
        name = item.name,
        isRouter = item.isRouter;
      this.$store.commit("setcurrentNav", { index, name });
      console.log(item,'itemitemitemitemitemitem');
      if (isRouter) {
        this.$router.push({ name: index });
        console.log(this.defaultActive);
        // this.defaultActive = "home"
        //收起展开的submenu
        //活跃的时候不关，做判断
        //收起展开的submenu
        let openedMenus = this.$refs["refmenu"].openedMenus;
        openedMenus.forEach((i) => {
          let openArr = this.leftNavData.filter((item) => {
            return item.index == i;
          })[0]["childe"];
          if (!openArr.includes(item)) {
            this.$refs["refmenu"].close(i);
          }
        });
        // if (this.$router.currentRoute.path === "/systemconfig" || this.$router.currentRoute.path === "/amt_columnconf") {
        // } else {
        //   let openedMenus = this.$refs["refmenu"].openedMenus;
        //   openedMenus.forEach((i) => {
        //     this.$refs["refmenu"].close(i);
        //   });
        // }
      } else if (this.$route != "modelmanage") {
        this.$router.push({ name: "modelmanage" });
        console.log(
          this.$router.push({ name: "modelmanage" }),
          'this.$router.push({ name: "modelmanage" })'
        );
      }
    },

    //获取用户信息
    // getUserInfo(){
    //   this.userInfo = this.$store.state.userInfo;
    // }
  },
  watch: {
    userInfo: {
      deep: true,
      handler: function (newVal) {
        this.userInfos = newVal;
        this.verifyFilterLeftNavList()
      },
    },
    // windowWidth:{
    //   deep:true,
    //   handler:function(newVal){
    //     if (newVal <= 1280 && this.isCollapse == false) {
    //     console.log("收导航栏");
    //     this.isCollapse = true;

    //   } else if (newVal > 1280 && this.isCollapse == true) {
    //     console.log("开导航栏");
    //     this.isCollapse = false;
    //   }
    //   }
    // }
  },
};
</script>

<style scoped lang="scss">
.top {
  // height: 116px;
  height: 88px;
  margin-bottom: 20px;
  margin-top: 10px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease;
  // background-image: linear-gradient(to top,#1174fe ,#3089ff);
}

.tops {
  height: 50px !important;
}

.el-menu--vertical .el-menu--popup .el-menu-item.is-active .submenuText2 {
  color: #9cccff !important;
  margin-left: 40px !important;
}

/* /deep/.el-menu--vertical .el-menu--popup .el-menu-item.submenu_item.is-active:hover {
  .submenuText2{
    color: #fff !important;
  }
} */
/deep/.el-menu--vertical
  .el-menu--popup
  .el-menu-item.is-active
  .submenuText2
  /deep/.el-menu--vertical
  .el-menu--popup
  .el-menu-item:hover
  .submenuText2 {
  color: #fff !important;
}

/deep/.el-menu-item.submenu_item:hover {
  span {
    color: #fff !important;
  }
}

.el-tooltip__popper .submenuText1 {
  margin-left: 0 !important;
}

.el-menu--vertical .el-menu--popup .el-menu-item .submenuText2 {
  margin-left: 40px !important;
}

.borderLeft {
  width: 3px;
  height: 100%;
  background-color: green;
}

.one_menu_item.is-active::before {
  content: "";
  width: 5px;
  height: 100%;
  background-color: pink;
  position: absolute;
  left: 0;
  background-image: linear-gradient(to top, #399e33, #effb13);
}

/deep/.el-submenu.is-active .el-submenu__title::before {
  content: "";
  width: 5px;
  height: 100%;
  background-color: pink;
  position: absolute;
  left: 0;
  background-image: linear-gradient(to top, #399e33, #effb13);
}

.top .logo {
  height: 50px;
  display: flex;
  align-items: center;
  transition: 0.2s all ease;
}

.submenuText1 {
  margin-left: 10px;
  width: 60px !important;
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}
// .el-menu-item i{
//   padding-left: 15px;
// }

// .el-submenu__title i{
//   padding-left: 15px;
// }

.submenuText2 {
  margin-left: 18px !important;
  width: 62px !important;
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}

.top .logo img {
  width: 90px;
  margin-top: 18px;
  transition: all 0.3s ease-in-out;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  // width: 215px;
  width: 160px;
  height: 100%;
}
.el-menu-item i {
  color: #ffffff;
}
.menu_box {
  overflow: hidden;
  /* height: calc(100vh - 130px); */
  height: calc(100vh - 113px);
}

.el-menu {
  background-color: unset;
  // background-color: #295e9e;
}
.el-menu-item.submenu_item.is-active {
  // color: #499afe;
  color: #fff !important;
  span {
    // border-bottom: 2px solid #feef35;
    color: #fff !important;
    &::after {
      content: "";
      width: 60px;
      height: 2px;
      background-color: #feef35;
      display: inline-block;
      position: absolute;
      top: 40px;
      left: 60px;
    }
  }
}
.submenu_item span {
  margin-left: 8px;
  padding-bottom: 5px;
  color: #9cccff;
}
.submenu_item:hover {
  background-color: #295e9e;
}

/deep/.el-submenu__icon-arrow {
  right: 13px !important;
  margin-top: -5px;
  // top: -5px;
}

/deep/.el-submenu .el-menu {
  background-color: #295e9e;
}

.one_menu_item.el-menu-item {
  color: #ffffff;
  padding-left: 0px;
  position: relative;
}

.one_menu_item.is-active {
  background-color: #fff !important;
  // border-left: 3px solid green;
  span {
    font-weight: bold;
  }
}

.one_menu_item:hover {
  background-color: #fff !important;
  color: #2e95fb !important;
  font-weight: bold;
  i {
    color: #2e95fb !important;
    font-weight: bold;
  }
}

.logo-img-small {
  transition: all 0.3s ease-in-out;
  width: 40px !important;
  margin-top: 10px !important;
}

/deep/.el-submenu__title:hover {
  background-color: #fff !important;
  color: #2e95fb !important;
  font-weight: bold;
  i {
    color: #2e95fb !important;
    font-weight: bold;
  }
}

/deep/.el-submenu.is-active .el-submenu__title {
  background-color: #fff !important;
  color: #2e95fb !important;
  font-weight: bold;
  i {
    color: #2e95fb !important;
    font-weight: bold;
  }
}

/deep/.el-menu-item:hover,
/deep/.el-menu-item:focus {
  background-color: #295e9e;
}

.el-scrollbar::-webkit-scrollbar {
  display: none !important;
}
.menu_box::-webkit-scrollbar-thumb {
  display: none;
}

.el-scrollbar__bar.is-vertical {
  right: 0px;
}

.el-scrollbar {
  height: 100%;
  overflow-x: hidden !important;
}

/deep/.is-horizontal {
  display: none !important;
}
</style>


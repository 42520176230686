<template>
  <div class="fromContainer">
    <el-form
      :model="form"
      status-icon
      :rules="rules"
      ref="form"
      label-width="100px"
      size="mini"
      labelWidth="auto"
      style="width: 100%"
    >
      <!-- left -->
      <el-row :gutter="20">
        <el-col :sm="24" :md="18" :lg="18" :xl="12" :offset="0">
          <el-form-item
            v-for="item in showFiledData"
            :key="item.row_id"
            :label="`${item.name}：`"
            :prop="item.en_name"
          >
            <!-- 文本框 -->
            <el-input
              v-if="item.show_type == 1"
              v-model="form[item.en_name]"
            ></el-input>

            <el-select
              v-if="currentNav == 'amt_module' && item.show_type == 22"
              v-model="form[item.en_name]"
              placeholder="请选择"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="select in sdata"
                :key="select.row_id"
                :label="select.name ? select.name : select.name_chn"
                :value="select.row_id"
              >
              </el-option>
            </el-select>

            <!-- 单选按钮 -->
            <fragment v-else-if="item.show_type == 2&& typeof form[item.en_name] === 'string'">

              <el-radio
                v-for="radio in selectdata[item.en_name]"
                v-model="form[item.en_name]"
                :label="radio.row_id+''"
                :key="radio.row_id"
                >{{ radio.name }}</el-radio
              >
            </fragment>
            <fragment v-else-if="item.show_type == 2&& typeof form[item.en_name] === 'number'">
              <el-radio
                v-for="radio in selectdata[item.en_name]"
                v-model="form[item.en_name]"
                :label="radio.row_id"
                :key="radio.row_id"
                >{{ radio.name }}</el-radio
              >
            </fragment>
            <!-- 多选按钮 -->
            <el-checkbox-group
              v-else-if="item.show_type == 3"
              v-model="checkList[item.en_name]"
              @change="handleChange(item.en_name)"
            >
              <el-checkbox
                v-for="checkbox in selectdata[item.en_name]"
                :key="checkbox.row_id"
                :label="checkbox.row_id"
                >{{ checkbox.name }}</el-checkbox
              >
            </el-checkbox-group>

            <!-- 选择框 -->
            <!-- 这里为了兼容类型不同，选择框异常问题 -->
            <!-- 选择框的值为数字类型 -->
            <el-select
              v-else-if="
                item.show_type == 4 && typeof form[item.en_name] === 'number'
              "
              v-model="form[item.en_name]"
              placeholder="请选择"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="select in selectdata[item.en_name]"
                :key="select.row_id"
                :label="select.name ? select.name : select.name_chn"
                :value="+select.row_id"
              >
              </el-option>
            </el-select>
            <!-- 选择框的值为字符串类型 -->
            <el-select
              v-else-if="
                item.show_type == 4 && typeof form[item.en_name] === 'string'
              "
              v-model="form[item.en_name]"
              placeholder="请选择"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="select in selectdata[item.en_name]"
                :key="select.row_id"
                :label="select.name ? select.name : select.name_chn"
                :value="select.row_id+''"
              >
              </el-option>
            </el-select>
            <!-- 新增的时候没有该字段，为undefined -->
            <el-select
              v-else-if="
                item.show_type == 4 && typeof form[item.en_name] === 'undefined'
              "
              v-model="form[item.en_name]"
              placeholder="请选择"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="select in selectdata[item.en_name]"
                :key="select.row_id"
                :label="select.name ? select.name : select.name_chn"
                :value="select.row_id"
              >
              </el-option>
            </el-select>
            <!-- 多行文本框 -->
            <el-input
              v-else-if="item.show_type == 5"
              v-model="form[item.en_name]"
              type="textarea"
              size="medium"
              rows="4"
              class="textareaNOPut"
            ></el-input>

            <!-- 图片上传 -->
            <image-upload
              v-else-if="item.show_type == 6"
              :imgsrc="form[item.en_name]"
              :filedname="item.en_name"
            ></image-upload>

            <!-- 时间选择器 -->

            <el-date-picker
              v-else-if="item.show_type == 7"
              v-model="form[item.en_name]"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
            ></el-date-picker>

            <!-- 富文本 -->
            <kind-editor
              v-else-if="item.show_type == 8"
              @kindeditorChange="kindeditorChange"
              width="100%"
              :content="form[item.en_name]"
              :field-name="item.en_name"
              :id="'kin' + item.row_id"
            ></kind-editor>

            <!-- 多图片上传 -->
            <images-upload
              v-else-if="item.show_type == 9"
              :imgsrc="form[item.en_name]"
            ></images-upload>

            <map-for-lon-lat
              @chageLatitudeNndLongitude="chageLatitudeNndLongitude(arguments)"
              :form="form"
              ref="map"
              v-else-if="item.show_type == 11"
            ></map-for-lon-lat>
            <!-- 文件上传 -->
            <file-upload
              v-else-if="item.show_type == 10"
              :filesrc="form[item.en_name]"
              :filedname="item.en_name"
            ></file-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          @click="clickSave"
          :disabled="disabled"
          >保存</el-button
        >
        <el-button @click="$emit('closetForm')">关闭</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getNowTime, failCheckPosition } from "@common/publicmethods";
import ImageUpload from "./ImageUpload";
import ImagesUpload from "./ImagesUpload";
import FileUpload from "./FileUpload";
import MapForLonLat from "./MapForLonLat";
import KindEditor from "@components/common/editor/EditTest";
export default {
  name: "FormData",
  components: {
    ImageUpload,
    FileUpload,
    KindEditor,
    MapForLonLat,
    ImagesUpload,
  },
  props: {
    showFiledData: {
      type: Array,
    },
    form: {
      type: Object,
      default: {},
    },
    selectdata: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      //  rules: CleanDataManageRules,
      isClear: false,
      currentNav: null,
      sdata: [
        { name: "功能模块", row_id: "1" },
        { name: "区域模块", row_id: "0" },
      ],
      disabled: false,
    };
  },
  mounted() {
    this.currentNav = this.$store.state.currentNav.index;
    this.$refs.map && this.$refs.map[0].$el.parentNode.previousSibling.firstChild.remove();
  },
  methods: {
    //当前选择地图经纬度获取
    chageLatitudeNndLongitude(arg) {
      this.$set(this.form, "longitude", arg[0]);
      this.$set(this.form, "latitude", arg[1]);
    },
    //点击关闭按钮
    closetForm() {
      this.$refs["tabbar"].removeTab("editpage");
    },
    closetForm() {},
    clickSave() {
      this.disabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 3000);

      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$emit("submitForm", this.form);
        } else {
          //验证失败定位失败位置
          failCheckPosition();
        }
      });
    },
    kindeditorChange(data, name) {
      console.log(data, name);
      this.form[name] = data;
    },
  },
};
</script>

<style scoped lang="scss">
 /deep/.el-form-item--mini.el-form-item:first-child{
  margin-top: 14px;
}
.fromContainer {
  padding-left: 50px;
  height: calc(100vh - 110px);
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>

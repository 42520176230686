export const leftNavData = [
    {
        index: 'home',
        name: '旅游资源',
        icon: 'el-icon-s-home',
        isRouter: true,
        rightName: 'home',
        isShow : true
    },
    {
        index: 'basicdata',
        name: '基础数据',
        icon: 'el-icon-s-order',
        rightName: 'basedata',
        isShow : true,
        childe: [
            {
                index: 'play',
                name: '景点管理',
                isRouter: true,
                rightName: 'playmanage',
                isShow : true
            },
            {
                index: 'stay',
                name: '住宿管理',
                rightName: 'staymanage',
                isShow : true
            },
            {
                index: 'food',
                name: '美食管理',
                rightName: 'foodmanage',
                isShow : true
            },
            {
                index: 'amuse',
                name: '娱乐管理',
                rightName: 'amusemanage',
                isShow : true
            },
            {
                index: 'pro_owner',
                name: '商店管理',
                rightName: 'promanage',
                isShow : true
            },
            {
                index: 'traffic',
                name: '出行管理',
                rightName: 'trafficmanage',
                isShow : true
            }
        ]
    },
    {
        index: 'travelCommunalFacilities',
        name: '景区设施',
        icon: 'el-icon-office-building',
        rightName: 'equipment',
        isShow : true,
        childe: [{
                index: 'toilet',
                name: '公共厕所',
                rightName: 'toilet',
                isShow : true
            },
             {
                index: 'carpark',
                name: '停车场',
                rightName: 'carpark',
                isShow : true
            },
						{
                index: 'spotlive',
                name: '景区直播',
                rightName: 'video',
                isShow : true
            },
        ]
    },
    {
        index: 'travelProduct',
        name: '旅游产品',
        icon: 'el-icon-shopping-bag-1',
        rightName: 'travelProduct',
        isShow : true,
        childe: [{
                index: 'commodity',
                name: '特色商品',
                isRouter: true,
                rightName: 'commodity',
                isShow : true
            }, {
                index: 'travel_line',
                name: '旅游路线',
                rightName: 'travelline',
                isShow : true
            }, {
                index: 'ticket',
                name: '景区门票',
                rightName: 'ticket',
                isShow : true
            },
            {
                index: 'order',
                name: '产品订单',
                isRouter: true,
                rightName: 'order',
                isShow : true
            },
            {
                index: 'writeoff',
                name: '订单核销',
                rightName: 'writeoff',
                isShow : true
            },
            {
                index: 'room',
                name: '酒店房间',
                rightName: 'room',
                isShow : true
                // isRouter: true
            },
            {
                index:'coupon',
                name:'优惠券',
                rightName:'coupon',
                isShow : true
            }
        ]
    },
    {
        index: 'tourismInfo',
        name: '资讯信息',
        icon: 'el-icon-chat-line-square',
        rightName: 'tourismInfo',
        isShow : true,
        childe: [{
                index: 'amt_informationpush',
                name: '信息推送',
                rightName: 'informationpush',
                isShow : true
            }, {
                index: 'amt_active',
                name: '优惠活动',
                rightName: 'active',
                isShow : true
            }, {
                index: 'newsinformation',
                name: '新闻资讯',
                rightName: 'newsinformation',
                isShow : true
            }, {
                index: 'amt_picshow',
                name: '美图欣赏',
                rightName: 'picshow',
                isShow : true
            }, {
                index: 'amt_videoshow',
                name: '精彩视频',
                rightName: 'videoshow',
                isShow : true
            }
            , {
                index: 'amt_strategy',
                name: '旅游攻略',
                rightName: 'strategy',
                isRouter: true,
                isShow : true
            }, {
                index: 'amt_telephone',
                name: '常用电话',
                rightName: 'telephone',
                isShow : true
            },{
                index: 'alarm',
                name: '旅游求助',
                rightName: 'sosinfo',
                isShow : true
            },

        ]
    },
    {
        index: 'travelagent',
        name: '游客互动',
        icon: 'el-icon-user',
        rightName: 'travelagent',
        isShow : true,
        childe: [{
            index: 'comment',
            name: '游客评论',
            rightName: 'comment',
            isShow : true
        }, {
            index: 'qainfo',
            name: '游客问答',
            rightName: 'qainfo',
            isShow : true
        }, {
            index: 'consultation',
            name: '游客咨询',
            rightName: 'consultation',
            isShow : true
        }, {
            index: 'complaint_case',
            name: '游客投诉',
            rightName: 'complaint',
            isShow : true
        }
    ]
    },
    {
        index: 'membercenter',
        icon: 'el-icon-s-finance',
        name: '会员中心',
        rightName: 'membercenter',
        isShow : true,
        childe: [ {
            index: 'member',
            name: '会员列表',
            rightName: 'member',
            isShow : true
        }, {
            index: 'member_recharge',
            name: '储值管理',
            rightName: 'recharge',
            isShow : true
        }, {
            index: 'member_tips',
            name: '标签管理',
            rightName: 'tips',
            isShow : true
        }, {
            index: 'pointmall',
            name: '积分商城',
            rightName: 'pointmall',
            isShow : true
        }, {
            index: 'exchange',
            name: '积分兑换',
            rightName: 'exchange',
            isShow : true
        },
    ]
    },
    {
        index: 'foo',
        icon: 'el-icon-s-data',
        name: '统计分析',
        isRouter: true,
        rightName: 'statistics',
        isShow : true,
    },
    {
        index: 'systemcConfig',
        icon: 'el-icon-setting',
        name: '系统配置',
        rightName: 'sysconfig',
        isShow : true,
        childe: [{
            index: 'amt_basicconfig',
            name: '基础配置',
            isRouter: true,
            rightName: 'baseconfig',
            isShow : true
        }, {
            index: 'amt_columnconfig',
            name: '栏目配置',
            isRouter: true,
            rightName: 'columnconfig',
            isShow : true
        }, {
            index: 'amt_resources',
            name: '资源配置',
            rightName: 'resourceconfig',
            isShow : true
        },
        {
            index: 'amt_module',
            name: '模块配置',
            rightName: 'modelconfig',
            isShow : true
        },
        {
            index: 'ticket_config',
            name: '票务配置',
            isRouter: true,
            rightName: 'tickettype',
            isShow : true
        },
        {
            index: 'amt_linkconfig',
            name: '链接配置',
            rightName: 'linkconfig',
            isShow : true
        },
        {
            index:'roomtype',
            name:'类型配置',
            isRouter: true,
            rightName:'roomtype',
            isShow : true
        },
        {
            index:'amt_interface',
            name:'接口管理',
            rightName:'interface',
            isShow : true
        }
    ]
    },
]
/*
    index请求接口编码
    isRouter是否单独路由页面
    rightName权限
*/